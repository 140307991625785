import mergeImages from "merge-images";
import { createContext, useCallback, useContext, useEffect, useMemo, useState } from "react";
import { useForm, useWatch } from "react-hook-form";
import { useAuth } from "./auth";

interface BuilderContextData {
  makeImagePreview(): void;
  previewImage: string;
  form: any;
  breed: string | undefined;
  gender: string | undefined;
  view: string | undefined;
  body: string | undefined;
  eyes: string | undefined;
  clothes: string | undefined;
  frame: string | undefined;
}

type BuilderProviderProps = {
  children: React.ReactNode;
}

const BuilderContext = createContext<BuilderContextData>({} as BuilderContextData);

export const BuilderProvider = ({ children }: BuilderProviderProps) => {
  const { setPrice } = useAuth();
  
  const [previewImage, setPreviewImage] = useState('');

  const form = useForm({
    mode: "onChange",
    defaultValues: {
      gender: "male",
      breed: "",
      eyes: "",
      body: "",
      view: "https://my-dog-nft.s3.sa-east-1.amazonaws.com/backgrounds/BCKG01.png",
      clothes: "",
      glasses: "",
      necklace: "",
      cap: "",
      arm: "",
      microphone: "",
      frame: "",
    },
  });

  const {
    body,
    view,
    eyes,
    clothes,
    glasses,
    gender,
    breed,
    necklace,
    cap,
    arm,
    microphone,
    frame
  } = useWatch({
    control: form.control,
  });

  const characterParts = useMemo(() => {
    return [
      view,
      body,
      eyes,
      clothes,
      glasses,
      necklace,
      cap,
      arm,
      microphone,
      frame
    ].filter(Boolean);
  }, [body, view, eyes, clothes, glasses, necklace, cap, arm, microphone, frame]);

  useEffect(() => {
    if (frame && frame === 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/frames/bronze+v5+-+final.png') {
      setPrice(50);
    } else if (frame && frame === 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/frames/prata+v5+-+final.png') {
      setPrice(250);
    } else if ( frame && frame === 'https://my-dog-nft.s3.sa-east-1.amazonaws.com/frames/ouro+v5+-+final.png') {
      setPrice(500);
    } else {
      setPrice(0);
    }
  }, [characterParts]);
  
  const makeImagePreview = useCallback(() => {
    mergeImages(characterParts as Array<string>, {
      crossOrigin: 'anonymous'
    }).then((b64) => 
      setPreviewImage(b64));
  }, [characterParts, setPreviewImage]);
  
  return (
    <BuilderContext.Provider value={{
      makeImagePreview,
      previewImage,
      form,
      breed,
      gender,
      view,
      body,
      eyes,
      clothes,
      frame
    }}>
      {children}
    </BuilderContext.Provider>
  );
}

export function useBuilder(): BuilderContextData {
  const context = useContext(BuilderContext);

  return context;
}