import { createContext, useCallback, useContext, useState } from "react";

interface AuthContextData {
  walletAddress: string;
  ipfsUrl: string;
  setIpfsUrl(ipfsUrl: string): void;
  signIn(walletAddress?: string): Promise<boolean>;
  price: number;
  setPrice(price: number): void;
  dogName: string;
  setDogName(name: string): void;
  transactionId: string;
  setTransactionId(transactionId: string): void;
  data: AuthState;
  sku: string;
  generateSku(itemName: string, itemType: string): void;
}

interface AuthState {
  walletAddress: string;
}

type AuthProviderProps = {
  children: React.ReactNode;
}

const AuthContext = createContext<AuthContextData>({} as AuthContextData);

export const AuthProvider = ({ children }: AuthProviderProps) => {
  const [data, setData] = useState<AuthState>({} as AuthState);
  const [ipfsUrl, setIpfsUrl] = useState<string>('');
  const [price, setPrice] = useState<number>(0);
  const [dogName, setDogName] = useState<string>('');
  const [transactionId, setTransactionId] = useState<string>('');
  const [sku, setSku] = useState<string>('');
  const [skuSelected, setSkuSelected] = useState<string[]>([]);

  const signIn = useCallback(async (walletAddressParam?: string): Promise<boolean> => {
    try {
      if (!walletAddressParam) {
        const userAccounts = await window.ethereum.request({method: 'eth_requestAccounts'});
        const walletAddressPlugin = userAccounts[0];
        setData({walletAddress: walletAddressPlugin} as AuthState);
      } else {
        setData({walletAddress: walletAddressParam} as AuthState);
      }
      
      return true;
    } catch (error) {
      console.log(error);
      return false;
    }
  }, []);

  const generateSku = (itemName: string, itemType: string) => {
    const skuTemp = skuSelected;

    if (itemType === 'body') skuTemp[0] = itemName.split('.')[0];
    if (itemType === 'eye') skuTemp[1] = itemName.split('.')[0].split('-')[1];
    if (itemType === 'clothe') skuTemp[2] = itemName.split('.')[0].split('-')[1];
    if (itemType === 'glass') skuTemp[3] = itemName.split('.')[0].split('-')[1];
    if (itemType === 'necklace') skuTemp[4] = itemName.split('.')[0].split('-')[1];
    if (itemType === 'cap') skuTemp[5] = itemName.split('.')[0].split('-')[1];
    if (itemType === 'arm') skuTemp[6] = itemName.split('.')[0].split('-')[1];

    setSkuSelected(skuTemp);
    setSku(skuSelected.join('-'));
  };

  return (
    <AuthContext.Provider value={{
      walletAddress: data.walletAddress, 
      signIn,
      ipfsUrl,
      setIpfsUrl,
      price,
      setPrice,
      dogName,
      setDogName,
      transactionId,
      setTransactionId,
      data,
      sku,
      generateSku
    }}>
      {children}
    </AuthContext.Provider>
  );
}

export function useAuth(): AuthContextData {
  const context = useContext(AuthContext);

  return context;
}