import { GlobalStyle, ThemeProvider } from "@doare/ui-components";
import type { AppProps } from "next/app";
import { AuthProvider } from "../hooks/auth";
import { BuilderProvider } from "../hooks/builder";

export default function App({ Component, pageProps }: AppProps) {
  return (
    <ThemeProvider>
      <AuthProvider>
        <BuilderProvider>
          <GlobalStyle />
          <Component {...pageProps} />
        </BuilderProvider>
      </AuthProvider>
    </ThemeProvider>
  );
}
